import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import travelVid from '../assets/videos/tech3.mp4';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Hero = () => {
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg src={travelVid} type="video/mp4" autoPlay loop muted playsInline />
            </HeroBg>
            <HeroContent>
                <HeroItems>
                    <HeroH3>Hello, world!</HeroH3>
                    <HeroH1>My name is <HeroNameSpan>Rui Tang</HeroNameSpan></HeroH1>
                    <HeroP>I develop things to make people's life easier</HeroP>
                    <Button onClick={() => scrollTo("#aboutSection")} primary="true" round="true" big="true">Know more</Button>
                </HeroItems>
            </HeroContent>
        </HeroContainer>
    )
};

export default Hero;

const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 100vh;
    padding: 0 1rem;
    position: relative;
    margin-top: -80px;

    :before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
        background: linear-gradient(
            180deg, 
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.6) 100%
        ),
        linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    }
`;

const HeroBg = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
`;

const HeroContent = styled.div`
    z-index: 3;
    height: calc(100vh - 80px);
    max-height: 100%;
    padding: 0rem calc((100vw - 1300px) / 2);
`;

const HeroItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 100vh;
    max-height: 100%;
    padding: 0;
    color: #00995c;
    line-height: 1.1;
    font-weight: bold;
`;

const HeroH1 = styled.h1`
    font-size: clamp(1.5rem, 6vw, 4rem);
    margin-bottom: 1.5rem;
    letter-spacing: 3px;
    padding: 0 1rem;
`;

const HeroNameSpan = styled.span`
    color: #b3ffe0;
    border-bottom: #b3ffe0 1.5px solid;
    padding-bottom: 5px;
`;

const HeroH3 = styled.h3`
    font-size: clamp(1rem, 3vw, 2rem);
    margin-bottom: 1.5rem;
    letter-spacing: 3px;
    padding: 0 1rem;
`;

const HeroP = styled.p`
    font-size: clamp(1rem, 3vw, 3rem);
    margin-bottom: 2rem;
    font-weight: 400;
`;
